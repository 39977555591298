import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Title, Text, Box, Button } from "../../components/Core";

// @ts-expect-error false positive image import failure
import imgL1FeatureCurve from "../../assets/image/svg/l1-curve-feature.svg";

// @ts-expect-error false positive image import failure
import imgL2HeroBg from "../../assets/image/home/hero.jpg";

const ShapeBottmRight = styled(Box)`
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  img {
    min-width: 100%;
  }
`;

const SectionStyled = styled(Section)`
  /* background: url(${imgL2HeroBg}) no-repeat; */
  background-size: contain;
  background-position: bottom left;
  position: relative;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  /* background-color: #000; */
  opacity: 0.3;
`;

const SubHeader = styled(Title)`
  font-size: 2rem;
  letter-spacing: 0px;
  margin-bottom: 10px;
`;

const Step = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 20px;
`;

const StepLabel = styled(Text)`
  font-size: 38px;
  font-weight: 700;
  margin-right: 10px;
  line-height: 30px;
`;

const StepText = styled(Text)`
  margin-bottom: -2px;
  line-height: 24px;
`;

const AnnotatedImage = styled.img`
  height: 600px;
  /* position: absolute; */
  right: 20px;
  bottom: 0px;
`;

interface Props {
  image: any;
  model: string;
  pdf?: string;
}

const Hero = ({ image, model, pdf }: Props) => {
  return (
    <SectionStyled position="relative" bg="#F7F7FB">
      <Overlay />
      <ShapeBottmRight>
        <img src={imgL1FeatureCurve} alt="" className="img-fluid" />
      </ShapeBottmRight>
      <div className="pt-5"></div>
      <Container>
        <Row className="align-content-center">
          <Col lg="6" sm="12">
            <Title textColor="dark">{model} Training</Title>
            <SubHeader textColor="primary">Daily Quick Start</SubHeader>
            <Step>
              <StepLabel color="primary">1.</StepLabel>
              <StepText color="dark">
                Turn on radio and set the volume to your preference
              </StepText>
            </Step>
            <Step>
              <StepLabel color="primary">2.</StepLabel>
              <StepText color="dark">
                Sign on and do a radio check with CCTV or another user
              </StepText>
            </Step>
            <Step>
              <StepLabel color="primary">3.</StepLabel>
              <StepText color="dark">
                Keep your radio on you or nearby to hear and report any
                incidents throughout the day
              </StepText>
            </Step>
            <Step>
              <StepLabel color="primary">4.</StepLabel>
              <StepText color="dark">
                At the end of the day sign off with CCTV before powering off the
                radio
              </StepText>
            </Step>
            <Step>
              <StepLabel color="primary">5.</StepLabel>
              <StepText color="dark">
                Place your radio in the charger, ready for the next day
              </StepText>
            </Step>
            {pdf && (
              <a href={pdf}>
                <Button mt={4} variant="outline" color="primary">
                  Download PDF Guide
                </Button>
              </a>
            )}
          </Col>

          <Col lg="6" sm="12" className="d-flex justify-content-center">
            <AnnotatedImage src={image} alt="Annotated image of a pd665." />
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  );
};

export default Hero;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";

import { Section, Title, Button } from "../../components/Core";

const CTA = () => {
  return (
    <Section py={5} bg="#F7F7FB" className="position-relative">
      <Container>
        <Row className="align-content-center justify-content-center text-center">
          <Col lg="10" md="12">
            <div className="py-2">
              <Title textColor="dark">Got a problem with your radio?</Title>
            </div>
          </Col>
          <Col lg="3" md="12" className="mt-3 ">
            <Link to="/contact">
              <Button>Get Support</Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default CTA;

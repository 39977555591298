import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Section, Title } from "../../components/Core";
import PostCard from "../../components/PostCard";

interface Props {
  radios: {
    to: string;
    image: any;
  }[];
}

const Radios = ({ radios }: Props) => {
  return (
    <Section py={0} className="position-relative">
      <Container>
        <Title textAlign="center" textColor="dark">
          Other Radios
        </Title>
        <Row className="align-items-center justify-content-center">
          {radios.map(({ to, image }) => (
            <Col lg="4" md="3" sm="10" className="mb-4 mb-lg-5">
              <PostCard
                img={image}
                buttonText="Start Training"
                to={to}
              ></PostCard>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
};

export default Radios;
